import styled from 'styled-components';

const SectionContainer = styled.div`
  background: white;

  .section-job-posts-hero--wrapper {
    background: ${(props) => props.background};
  }

  .section-job-posts-hero--container {
    margin: 0 auto;
    padding: 80px 0;

    .section-job-posts-hero--title {
      text-align: center;
      letter-spacing: var(--letter-spacing--0-04);
      color: var(--darkest);
      max-width: 700px;
      margin: 0 auto;
      padding: 0 28px;
    }

    .section-job-posts-hero--description {
      font-size: var(--font-size-h5);
      color: var(--darkest);
      text-align: center;
      max-width: 840px;
      margin: 0 auto;
      margin-top: 24px;
      padding: 0 28px;
    }

    .section-job-posts-hero--card-content {
      margin: 0 auto;
      padding: 80px 28px 0;
      max-width: 1252px;

      .section-job-posts-hero--card-container {
        margin-bottom: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    .section-job-posts-hero--container {
      .section-job-posts-hero--title {
        padding: 0 20px;
      }

      .section-job-posts-hero--description {
        font-size: var(--font-size-16);
        margin-top: 20px;
        padding: 0 20px;
      }

      .section-job-posts-hero--card-content {
        padding: 35px 0 20px;
      }
    }
  }

  @media (max-width: 575px) {
    .section-job-posts-hero--container {
      margin: 0 auto;
      padding-bottom: 0;

      .section-job-posts-hero--card-content {
        padding-top: 30px;

        .section-job-posts-hero--card-container {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export { SectionContainer };
