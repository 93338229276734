import React from 'react';
import { SectionContainer } from './styles';
import { JobListingPosts } from 'components/Page/Careers/JobListing/Posts';

const JobPostsHeroSection = ({ title, description, ...otherProps }) => {
  return (
    <SectionContainer {...otherProps} id="job-posts">
      <div className="section-job-posts-hero--wrapper">
        <div className="section-job-posts-hero--container">
          <h1 className="section-job-posts-hero--title font-h2">{title}</h1>
          <p className="section-job-posts-hero--description">{description}</p>
          <div className="section-job-posts-hero--card-content">
            <JobListingPosts />
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default JobPostsHeroSection;
